<template>
  <div v-if="!isReadonly">
    <div class="task__inner">
      <ul class="task__answers">
        <TrainingQuizAnswer
          v-for="(answer, idx) in filteredAnswers"
          :key="answer.id"
          :value="userAnswers[idx][1]"
          :answer="answer"
          :index="idx"
          :is-revealed="isRevealed"
          :is-readonly="isReadonly"
          is-mapping-type
          tag="li"
        >
          <Draggable
            v-model="userAnswers[idx]"
            group="availableValues"
            draggable=".answer__element--value"
            :animation="200"
            class="answer__inner"
            @change="(evt) => onChange(evt, userAnswers[idx])"
          >
            <div
              v-for="userAnswer in userAnswers[idx]"
              :key="userAnswer.uuid"
              :class="['answer__element', userAnswer.isKey ? 'answer__element--key text--primary': 'answer__element--value']"
            >
              <span class="bvi-speech">{{ userAnswer.title }}</span>

              <template v-if="userAnswer.isKey">
                <VBtn
                  v-if="userAnswers[idx].length > 1"
                  :title="$t('remove.one')"
                  fab
                  x-small
                  class="answer__button"
                  @click="unlinkAvailableAnswer(userAnswers[idx])"
                >
                  <VIcon>{{ 'link_off' }}</VIcon>
                </VBtn>

                <VBtn
                  v-else
                  :title="$t('add.one')"
                  fab
                  x-small
                  color="primary"
                  class="answer__button"
                  @click="selectAnswer(userAnswers[idx])"
                >
                  <VIcon>{{ 'link' }}</VIcon>
                </VBtn>
              </template>
            </div>
          </Draggable>
        </TrainingQuizAnswer>
      </ul>

      <Draggable
        v-model="initialAvailableAnswers"
        group="availableValues"
        @start="unselectAnswer"
      >
        <TransitionGroup
          tag="ul"
          type="transition"
          class="answer__available-values"
        >
          <TrainingQuizAnswer
            v-for="answer in initialAvailableAnswers"
            :key="answer.uuid"
            class="answer__available-value"
            :answer="answer"
            tag="li"
            is-mapping-type
          >
            <div
              :class="['answer__element', 'answer__element--value', 'bvi-speech', {'answer__element--selectable': selectedAnswer}]"
              @click="linkAvailableAnswer(answer)"
            >
              {{ answer.title }}
            </div>
          </TrainingQuizAnswer>
        </TransitionGroup>
      </Draggable>
    </div>
  </div>

  <VContainer
    v-else
    fluid
  >
    <template v-for="(answer, idx) in answers">
      <VDivider
        v-if="idx !== 0"
        :key="idx"
      />
      <VRow
        :key="answer.id"
        no-gutters
      >
        <template v-if="answer.settings">
          <VCol
            cols="12"
            sm="6"
          >
            <TrainingQuizAnswer
              :answer="answer"
              :is-readonly="isReadonly"
              :is-show-correct-answer="isShowCorrectAnswer"
              is-mapping-type
              class="transparent"
            >
              {{ answer.settings.userAnswerKey }}
            </TrainingQuizAnswer>
          </VCol>
          <VCol
            cols="12"
            sm="6"
          >
            <TrainingQuizAnswer
              :answer="answer"
              :is-revealed="isRevealed"
              :is-readonly="isReadonly"
              :is-show-correct-answer="isShowCorrectAnswer"
              is-mapping-type
            >
              <div class="flex-grow-1">
                {{ answer.settings.userAnswerValue }}
                <VAlert
                  v-if="isShowCorrectAnswer && !answer.settings.isCorrect"
                  dense
                  outlined
                  color="success"
                  class="mt-2 body-2"
                >
                  <details class="transparent ma-0 pa-0">
                    <summary>Правильный ответ:</summary>
                    <p>{{ answer.settings.value }}</p>
                  </details>
                </VAlert>
              </div>
            </TrainingQuizAnswer>
          </VCol>
        </template>
      </VRow>
    </template>
  </VContainer>
</template>

<script>
import Draggable from 'vuedraggable'

import TrainingQuizAnswer from '@components/TrainingQuizAnswer.vue'

export default {
  name: 'TrainingQuizQuestionTypeMapping',

  components: {
    Draggable,
    TrainingQuizAnswer
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    isRevealed: {
      type: Boolean,
      default: false
    },

    isReadonly: {
      type: Boolean,
      default: false
    },

    isShowCorrectAnswer: {
      type: Boolean,
      default: false
    },

    isMultipleChoice: {
      type: Boolean,
      default: false
    },

    answers: {
      type: Array,
      default: () => []
    },

    availableAnswers: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      userAnswers: [],
      initialAvailableAnswers: [],
      selectedAnswer: null
    }
  },

  computed: {
    filteredAnswers () {
      return this.answers.filter(a => a.settings.key)
    },

    formattedUserAnswers () {
      const MAX_LENGTH_ANSWER = 2
      const _userAnswers = this.userAnswers
        .filter(userAnswer => userAnswer.length === MAX_LENGTH_ANSWER)
        .map(([key, value]) => `${key.id},${value.id}`)

      return _userAnswers.length === this.filteredAnswers.length ? _userAnswers : []
    }
  },

  created () {
    this.register()
  },

  methods: {
    selectAnswer (answer) {
      this.selectedAnswer = answer
    },

    unselectAnswer () {
      this.selectedAnswer = null
    },

    unlinkAvailableAnswer (answer, availableAnswer) {
      if (!answer) {
        return
      }

      const idx = answer.findIndex(v => !v.isKey && (v.id !== availableAnswer?.id))
      this.initialAvailableAnswers.push(...answer.splice(idx, 1))
      this.$emit('input', this.formattedUserAnswers)
    },

    linkAvailableAnswer (availableAnswer, emitable = true) {
      if (!this.selectedAnswer || !availableAnswer) {
        return
      }

      this.selectedAnswer.push(availableAnswer)
      this.unselectAnswer()
      this.initialAvailableAnswers = this.initialAvailableAnswers.filter(v => v.id !== availableAnswer.id)

      if (emitable) {
        this.$emit('input', this.formattedUserAnswers)
      }
    },

    onChange (evt, userAnswer) {
      // Соответствие должно быть 1 к 1 (поэтому 2)
      const MAX_LENGTH_ANSWER = 2
      const availableAnswer = evt.added?.element
      if (availableAnswer && userAnswer.length > MAX_LENGTH_ANSWER) {
        this.unlinkAvailableAnswer(userAnswer, availableAnswer)
      } else {
        this.$emit('input', this.formattedUserAnswers)
      }
    },

    register () {
      this.initialAvailableAnswers = this.availableAnswers
        .map((answer, idx) => Object.assign({
          id: answer.answer_id,
          uuid: `value_${idx}`,
          title: answer.answer_value,
          isKey: false
        }, answer))

      this.filteredAnswers.forEach((answer, idx) => {
        this.userAnswers.push([{
          id: answer.id,
          uuid: `key_${idx}`,
          title: answer.settings.key,
          isKey: true
        }])

        if (answer.settings.userAnswerValue) {
          const [availableAnswer] = this.initialAvailableAnswers.filter(a => a.answer_id === answer.settings.userAnswerId)
          this.selectAnswer(this.userAnswers[idx])
          this.linkAvailableAnswer(availableAnswer, false)
        }
      })
    }
  },
}
</script>

<style lang="scss">
  .answer__inner {
    display: grid;
    flex: 1 1 100%;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .answer__button {
    position: absolute;
    right: 50%;
    top: calc(100% + 1px);
    transform: translate(50%, -50%);
    z-index: 2;

    @include media-breakpoint-up(md) {
      right: -1px;
      top: 50%;
      grid-template-columns: 1fr 1fr;
    }
  }

  .answer__element {
    position: relative;
    border-radius: $border-radius-root;
    border: $border;
    padding: 1rem;
  }

  .answer__element--key {
    order: -1;
  }

  .answer__element--value {
    width: 100%;
    cursor: move;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-left: 2rem;
    }

    &::after {
      content: '';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: $border;
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        left: -1px;
        top: 50%;
      }
    }
  }

  .answer__element--selectable {
    border-color: var(--v-primary-base);

    &::after {
      border-color: var(--v-primary-base);
    }
  }

  .answer__available-values {
    list-style: none;
    align-items: start;
    gap: 0.5rem;
    padding-bottom: 0.5rem;

    .answer__available-value {
      overflow: hidden;
      margin-top: 0.5rem;
    }
  }

  .answer__element--value,
  .sortable-ghost {
    grid-column: 1;
    grid-row: 2;

    @include media-breakpoint-up(md) {
      grid-column: 2;
      grid-row: 1;
    }
  }

  .sortable-ghost {
    overflow: hidden;
    background-color: #fff;
    z-index: 1;
  }
</style>
